import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import AppMain from "../../Layout/AppMain";
import axios from "axios";
import { BASE_URL } from "../../utils/API";
import {
  Modal,
  ModalBody,
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import BackgroundImgae from "../../assets/utils/images/Fondo-foto-Data-SEPSv2.png";
import Loader from "react-loaders";
import { toast } from "react-toastify";
import {encode as base64_encode} from 'base-64';

export const AuthContext = createContext({
  state: {},
  dispatch: {},
  authContext: () => {},
});

const Main = (props) => {
  const [formLogin, setFormLogin] = useState({
    username: "",
    password: "",
    entity: "",
  });

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
            entityId: action.entityId,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignOut: false,
            userToken: action.token,
            isLoading: false,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignOut: true,
            userToken: null,
            entityId: null,
          };
        case "SET_TRAVEL_INFO":
          return {
            ...prevState,
            travelInfo: action.travelData,
          };
        case "LOGIN":
          return {
            ...prevState,
            isLoading: action.loading,
          };
        case "MODAL_LOGIN":
          return {
            ...prevState,
            isModal: action.modal,
          };
        case "ENTITY":
          return {
            ...prevState,
            entityId: action.entityId,
          };
        case "MENU":
          return {
            ...prevState,
            menuTotal: action.menuTotal,
          };
      }
    },
    {
      isLoading: false,
      isModal: false,
      isSignOut: false,
      userToken: null,
      entityId: null,
      menuTotal: [],
    }
  );

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token

        dispatch({ type: "LOGIN", loading: true });

        const encryptedPassword = base64_encode(data.password);

        let requestData = JSON.stringify({
          login: {
            user: data.username,
            password: encryptedPassword,
          },
        });
        let config = {
          method: "post",
          url: `${BASE_URL}/login/request`,
          headers: {
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        axios(config)
          .then(function (response) {
            // alert(response.status );
            if (response.status == "200") {
              let temp_user = response.data;
              /*    temp_user.accesstoken = response.headers.accesstoken;
                 temp_user.refreshtoken = response.headers.refreshtoken; */

              dispatch({ type: "SIGN_IN", token: temp_user });
              sessionStorage.setItem("userToken", JSON.stringify(temp_user));

              if (temp_user.login.entities.length == 1) {
                dispatch({
                  type: "ENTITY",
                  entityId: temp_user.login.entities[0].ruc,
                });
                dispatch({ type: "MODAL_LOGIN", modal: false });
                sessionStorage.setItem(
                  "entityId",
                  temp_user.login.entities[0].ruc
                );
              }
            } else {
              toast["warning"](
                "Usted no se encuentra autorizado para acceder a este reporte. Para mayor información, por favor contáctese al correo estadisticas@seps.gob.ec"
              );
              dispatch({ type: "LOGIN", loading: false });
            }
          })

          .catch((error) => {
            toast["warning"](error.response.data.login);
            dispatch({ type: "LOGIN", loading: false });
          });

        /* .catch((error) => {
            //alert('Oops', 'Usuario y/o credenciales incorrectas');
            toast["warning"]("Usted no se encuentra autorizado para acceder a este reporte. Para mayor información, por favor contáctese al correo estadisticas@seps.gob.ec")
            dispatch({ type: 'LOGIN', loading: false });
          }); */
      },
      signOut: async () => {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("entityId");
        dispatch({ type: "SIGN_OUT" });
        window.location.href = "/";
      },
    }),
    []
  );

  const getMenu = async (rol = "") => {
    let filter = "";

    if (rol != "") filter = `\\${rol}`;
    const response = await fetch(`${BASE_URL}/menu${filter}`);
    const json = await response.json();

    dispatch({ type: "MENU", menuTotal: json.menuNav });
    localStorage.setItem("menu", JSON.stringify(json.menuNav));
  };

  const handleSubmit = () => {
    if (!state.userToken) {
      if (formLogin.username == "") {
        toast["warning"]("No ha ingresado su USUARIO.");
        return false;
      }

      if (formLogin.password == "") {
        toast["warning"]("No ha ingresado su CONTRASEÑA.");
        return false;
      }

      authContext.signIn({
        username: formLogin.username,
        password: formLogin.password,
      });
    } else {
      if (formLogin.entity == "") {
        toast["warning"]("Debe seleccionar la ENTIDAD.");
        return false;
      }

      dispatch({ type: "ENTITY", entityId: formLogin.entity });
      dispatch({ type: "MODAL_LOGIN", modal: false });

      sessionStorage.setItem("entityId", formLogin.entity);
    }
  };

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;
      let entityId;
      try {
        userToken = await sessionStorage.getItem("userToken");
        entityId = await sessionStorage.getItem("entityId");
      } catch (e) {
        // Restoring token failed
      }
      // After restoring token, we may need to validate it in production apps
      dispatch({
        type: "RESTORE_TOKEN",
        token: JSON.parse(userToken),
        entityId: entityId,
      });
      // This will switch to the App screen or Auth screen and this loading
      // screen will be unmounted and thrown away.
    };
    bootstrapAsync().then(() => {});
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      let rol_id = ``;

      if (state.userToken && state.entityId) {
        rol_id = state.userToken.login.entities.filter(
          (item) => item.ruc == state.entityId
        )[0].nemonicoRol;
      }
      await getMenu(rol_id);
    }

    fetchMyAPI();
  }, [state.userToken, state.entityId]);

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <AuthContext.Provider
          value={{ state: state, dispatch: dispatch, authContext: authContext }}
        >
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1250 },
              {
                "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
              },
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}
          >
            <AppMain />

            {/* <!-- MODAL LOGIN --> */}
            <Modal
              className="modal-login modal-lg"
              isOpen={state.isModal}
              toggle={() =>
                dispatch({ type: "MODAL_LOGIN", modal: !state.isModal })
              }
            >
              {/* <ModalHeader toggle={() => dispatch({ type: 'MODAL_LOGIN', modal: !state.isModal })}>Modal title</ModalHeader> */}
              <ModalBody id="modal-body">
                <img src={BackgroundImgae} />

                <div
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    width: "100%",
                  }}
                >
                  <div className="d-flex  justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box ">
                      <div className="modal-dialog w-100 mx-auto ">
                        <div
                          className="modal-content"
                          style={{
                            background: "#1568ad",
                            /* opacity: '0.95' */
                          }}
                        >
                          <div className="modal-body">
                            <div className="h5 modal-title text-center">
                              <h4 className="mt-2">
                                <b className="text-white">BIENVENIDO</b>
                                <p className="text-white text-justify h6 mt-2">
                                  Por favor ingresar su usuario y contraseña
                                  correspondiente a servicios en línea de la
                                  SEPS.
                                </p>
                              </h4>
                            </div>
                            <Form>
                              <Row form>
                                {!state.userToken ? (
                                  <>
                                    <Col md={6}>
                                      <FormGroup>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <span className="lnr-user input-group-text"></span>
                                          </InputGroupAddon>
                                          <Input
                                            placeholder="Usuario"
                                            name="username"
                                            value={formLogin.username}
                                            onChange={(e) => {
                                              e.persist();
                                              setFormLogin((prevState) => ({
                                                ...prevState,
                                                username: e.target.value,
                                              }));
                                            }}
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                      <FormGroup>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <span className="lnr-lock input-group-text"></span>
                                          </InputGroupAddon>
                                          <Input
                                            type="password"
                                            placeholder="Contraseña"
                                            name="password"
                                            // value={formLogin.password}
                                            onChange={(e) => {
                                              e.persist();
                                              setFormLogin((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                              }));
                                            }}
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                      <p className="text-white text-right mb-3">
                                        Reseteo de claves{" "}
                                        <a
                                          className="text-white"
                                          href="https://servicios.seps.gob.ec/sca/login/passwordRecovery.jsf"
                                          target="_blank"
                                        >
                                          ingresar aquí
                                        </a>
                                      </p>
                                    </Col>
                                  </>
                                ) : (
                                  <Col md={12}>
                                    <FormGroup>
                                      <CustomInput
                                        type="select"
                                        name="entity"
                                        value={formLogin.entity}
                                        onChange={(e) => {
                                          e.persist();
                                          setFormLogin((prevState) => ({
                                            ...prevState,
                                            entity: e.target.value,
                                          }));
                                        }}
                                      >
                                        <option value="">Seleccione...</option>
                                        {state.userToken.login.entities.map(
                                          (item) => (
                                            <option value={item.ruc}>
                                              {item.razonSocial}
                                            </option>
                                          )
                                        )}
                                      </CustomInput>
                                    </FormGroup>
                                  </Col>
                                )}
                              </Row>

                            </Form>

                            <div className="text-center">
                              {!state.isLoading ? (
                                <Button
                                  size="sm"
                                  className="btn-wide"
                                  color="white"
                                  onClick={handleSubmit}
                                >
                                  INGRESAR
                                </Button>
                              ) : (
                                <Loader color="#fff" type="ball-pulse" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </AuthContext.Provider>
      )}
    />
  );
};

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
