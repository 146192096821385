import "./polyfills";
import React, { createContext } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";

const store = configureStore();
const rootElement = document.getElementById("root");

export const AuthContext = createContext({
  state: {},
  dispatch: {},
  authContext: () => { },
});

const renderApp = (Component) => {

  ReactDOM.render(
    <Provider store={store}>
      {/* <AuthContext.Provider
        value={{ state: state, dispatch: dispatch, authContext: authContext }}> */}
      <HashRouter>
        <Component />
      </HashRouter>
      {/* </AuthContext.Provider> */}
    </Provider >
    ,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
