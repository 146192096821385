import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

import { MatomoProvider, createInstance, useMatomo } from '@datapunt/matomo-tracker-react'

const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));
const Components = lazy(() => import("../../DemoPages/Components"));

const instance = createInstance({
    urlBase: 'https://servicios.seps.gob.ec/piwik/',
    siteId: 37,
    // userId: 'UID76903202', // optional, default value: `undefined`.
    // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST'
    }
  })

const AppMain = () => {

    const { enableLinkTracking } = useMatomo()
    enableLinkTracking()

    return (
        <MatomoProvider value={instance}>
            <Fragment>

                {/* Components */}

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">
                                Please wait while we load all the Components
                                <small>We load at once all the Components.</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/components" component={Components} />
                </Suspense>

                {/* Dashboards */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-cy" />
                            </div>
                            <h6 className="mt-3">
                                Please wait while we load all the Dashboards
                                <small>We load at once all the Dashboards.</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/dashboards" component={Dashboards} />
                </Suspense>

                <Route exact path="/" render={() => (
                    <Redirect to="/dashboards/home" />
                )} />
                <ToastContainer />
            </Fragment>
        </MatomoProvider>
    )
};

export default AppMain;
